
import { objectConnectionPoints } from '@icepanel/app-graphics'
import { DiagramConnection, DiagramContentPartial, LineShape, PermissionType, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

interface Item {
  activeEvery: boolean
  activeSome: boolean
  click: () => void
  icon: string
  id: string
  title: string
}

@Component({
  name: 'ModelConnectionShape'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly connection!: DiagramConnection | DiagramConnection[]
  @Prop() readonly permission!: PermissionType

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get connections () {
    return this.connection instanceof Array ? this.connection : [this.connection]
  }

  get editable () {
    return this.permission !== 'read'
  }

  get multiple () {
    return this.items.every(o => !o.activeEvery)
  }

  get activeItems () {
    return this.items.filter(o => o.activeSome)
  }

  get items (): Item[] {
    return [
      {
        activeEvery: this.connections.every(o => o.lineShape === 'curved'),
        activeSome: this.connections.some(o => o.lineShape === 'curved'),
        click: () => this.updateShape('curved'),
        icon: '$custom-regular-wave-sine',
        id: 'curved',
        title: 'Curved line'
      },
      {
        activeEvery: this.connections.every(o => o.lineShape === 'straight'),
        activeSome: this.connections.some(o => o.lineShape === 'straight'),
        click: () => this.updateShape('straight'),
        icon: '$custom-horizontal-rule',
        id: 'straight',
        title: 'Straight line'
      },
      {
        activeEvery: this.connections.every(o => o.lineShape === 'square'),
        activeSome: this.connections.some(o => o.lineShape === 'square'),
        click: () => this.updateShape('square'),
        icon: '$custom-regular-wave-square',
        id: 'square',
        title: 'Square line'
      }
    ]
  }

  get currentItem () {
    return this.items.find(o => o.activeEvery)
  }

  updateShape (lineShape: LineShape) {
    const diagramProps: DiagramContentPartial = {}
    const revertDiagramProps: DiagramContentPartial = {}

    const connections = { ...this.currentDiagram.connections }

    this.connections
      .filter(o => o.lineShape !== lineShape)
      .forEach(o => {
        revertDiagramProps.connections = {
          ...revertDiagramProps.connections,
          $update: {
            ...revertDiagramProps.connections?.$update,
            [o.id]: {
              lineShape: o.lineShape
            }
          }
        }
        connections[o.id] = {
          ...o,
          lineShape
        }
        diagramProps.connections = {
          ...diagramProps.connections,
          $update: {
            ...diagramProps.connections?.$update,
            [o.id]: {
              lineShape
            }
          }
        }

        Object.entries(objectConnectionPoints(Object.values(this.currentDiagram.objects), Object.values(connections))).forEach(([id, points]) => {
          revertDiagramProps.connections = {
            ...revertDiagramProps.connections,
            $update: {
              ...revertDiagramProps.connections?.$update,
              [id]: {
                ...revertDiagramProps.connections?.$update?.[id],
                points: connections[id].points
              }
            }
          }
          connections[id] = {
            ...connections[id],
            points
          }
          diagramProps.connections = {
            ...diagramProps.connections,
            $update: {
              ...diagramProps.connections?.$update,
              [id]: {
                ...diagramProps.connections?.$update?.[id],
                points
              }
            }
          }
        })
      })

    const revertTasks: Task[] = [{
      id: this.currentDiagram.id,
      props: revertDiagramProps,
      type: 'diagram-content-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, diagramProps)
    this.diagramModule.setDiagramContentVersion(diagramContent)
    this.editorModule.addToTaskQueue({
      func: () => this.diagramModule.diagramContentUpdate({
        diagramId: this.currentDiagram.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })
  }
}
