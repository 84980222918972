
import { DiagramConnection, ModelConnection, ModelObjectType, PermissionType } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { iconUrlForTheme } from '@/helpers/theme'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { ModelModule } from '../../store'
import ObjectFamilyMenu from '../objects/object-family-menu.vue'

@Component({
  components: {
    ObjectFamilyMenu
  },
  name: 'ModelConnectionSender'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly permission!: PermissionType
  @Prop() readonly model!: ModelConnection
  @Prop() readonly connection!: DiagramConnection

  menuOpen = false
  searchTerm = ''
  searchFocused = false

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.$params.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)!
  }

  get editable () {
    return this.permission !== 'read'
  }

  get origin () {
    return this.modelModule.objects[this.model.originId]
  }

  get icon () {
    return this.origin.icon ? iconUrlForTheme(this.origin.icon) : undefined
  }

  get objectItems () {
    const order: ModelObjectType[] = ['system', 'group', 'actor', 'app', 'store', 'component']
    return Object
      .values(this.currentDiagram.objects)
      .filter(o => o.modelId !== this.model.targetId)
      .map(o => [
        {
          model: this.modelModule.objects[o.modelId],
          object: o
        },
        ...Object.values(this.modelModule.objects).filter(e => e.parentIds.includes(o.modelId)).map(m => ({
          model: m,
          object: o
        }))
      ])
      .flat()
      .filter(o => o.model && o.model.id !== this.model.originId)
      .sort((a, b) => a.model.type === b.model.type ? a.model.name.localeCompare(b.model.name) : order.indexOf(a.model.type) > order.indexOf(b.model.type) ? 1 : -1)
      .map(o => ({
        icon: o.model.icon ? iconUrlForTheme(o.model.icon) : undefined,
        subtitle: `${o.model.type.slice(0, 1).toUpperCase()}${o.model.type.slice(1)}`,
        title: o.model.name || `${o.model.type.slice(0, 1).toUpperCase()}${o.model.type.slice(1)}`,
        to: {
          query: this.$setQuery({
            connection: undefined,
            connection_reassign_dialog: `${this.connection.id}_${o.model.id}`
          })
        }
      }))
  }

  get filterObjectItemsFuzzy () {
    return new Fuse(this.objectItems, {
      keys: [
        'title',
        'subtitle'
      ],
      threshold: 0.3
    })
  }

  get items () {
    return this.searchTerm ? this.filterObjectItemsFuzzy.search(this.searchTerm).map(o => o.item) : this.objectItems
  }
}
