
import { ModelConnectionDirection, Task } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TeamModule } from '@/modules/team/store'
import { VersionModule } from '@/modules/version/store'

import { ModelModule } from '../../store'

@Component({
  components: {
    Menu
  },
  name: 'ModelConnectionAssignMenu'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop({ default: false }) readonly disabled?: boolean
  @Prop() readonly position!: { x: number, y: number }
  @Prop() readonly connectionId!: string
  @Prop() readonly originModelId!: string | null
  @Prop() readonly targetModelId!: string | null

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentConnectionId () {
    return this.$queryValue('connection')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentConnection () {
    return Object.values(this.currentDiagramContent?.connections || {}).find(o => o.id === this.currentConnectionId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContentModelConnectionIds () {
    return Object.values(this.currentDiagramContent?.connections || {}).map(o => o.modelId).filter((o): o is string => !!o)
  }

  get modelConnectionItemsHeight () {
    return Math.min(5 * 32, this.modelConnectionItemsFuzzy.length * 32)
  }

  get directionIcons () {
    return (direction: ModelConnectionDirection) => {
      switch (direction) {
        case 'outgoing': return '$fas-long-arrow-alt-right'
        case 'bidirectional': return '$fas-arrows-alt-h'
        default: return '$custom-solid-horizontal-rule'
      }
    }
  }

  get directConnections () {
    return Object.values(this.modelModule.connections).filter(o => {
      if (o.direction === 'bidirectional' && ((o.originId === this.originModelId && o.targetId === this.targetModelId) || (o.originId === this.targetModelId && o.targetId === this.originModelId))) {
        return true
      } else if (o.originId === this.originModelId && o.targetId === this.targetModelId) {
        return true
      } else {
        return false
      }
    })
  }

  get lowerConnections () {
    return Object.values(this.modelModule.connections).filter(o => {
      const originFamilyIds = [o.originId, ...this.modelModule.objects[o.originId]?.parentIds || []]
      const targetFamilyIds = [o.targetId, ...this.modelModule.objects[o.targetId]?.parentIds || []]
      if ((!o.direction || o.direction === 'bidirectional') && ((o.originId === this.originModelId && o.targetId === this.targetModelId) || (o.originId === this.targetModelId && o.targetId === this.originModelId))) {
        return false
      } else if (o.direction === 'outgoing' && o.originId === this.originModelId && o.targetId === this.targetModelId) {
        return false
      } else if ((!o.direction || o.direction === 'bidirectional') && ((this.originModelId && this.targetModelId && originFamilyIds.includes(this.originModelId) && targetFamilyIds.includes(this.targetModelId)) || (this.originModelId && this.targetModelId && originFamilyIds.includes(this.targetModelId) && targetFamilyIds.includes(this.originModelId)))) {
        return true
      } else if (o.direction === 'outgoing' && this.originModelId && this.targetModelId && originFamilyIds.includes(this.originModelId) && targetFamilyIds.includes(this.targetModelId)) {
        return true
      } else {
        return false
      }
    })
  }

  get higherConnections () {
    const originFamilyIds = this.originModelId ? [this.originModelId, ...this.modelModule.objects[this.originModelId]?.parentIds || []] : undefined
    const targetFamilyIds = this.targetModelId ? [this.targetModelId, ...this.modelModule.objects[this.targetModelId]?.parentIds || []] : undefined
    return Object.values(this.modelModule.connections).filter(o => {
      if ((!o.direction || o.direction === 'bidirectional') && ((o.originId === this.originModelId && o.targetId === this.targetModelId) || (o.originId === this.targetModelId && o.targetId === this.originModelId))) {
        return false
      } else if (o.direction === 'outgoing' && o.originId === this.originModelId && o.targetId === this.targetModelId) {
        return false
      } else if ((!o.direction || o.direction === 'bidirectional') && ((originFamilyIds?.includes(o.originId) && targetFamilyIds?.includes(o.targetId)) || (originFamilyIds?.includes(o.targetId) && targetFamilyIds?.includes(o.originId)))) {
        return true
      } else if (o.direction === 'outgoing' && originFamilyIds?.includes(o.originId) && targetFamilyIds?.includes(o.targetId)) {
        return true
      } else {
        return false
      }
    })
  }

  get modelConnectionItems () {
    const originProtected = this.originModelId ? this.isModelObjectProtected(this.originModelId) : undefined
    return [
      ...this.directConnections.map(o => ({
        connection: o,
        type: 'direct'
      })),
      ...this.lowerConnections.map(o => ({
        connection: o,
        type: 'lower'
      })),
      ...this.higherConnections.map(o => ({
        connection: o,
        type: 'higher'
      }))
    ]
      .filter(o => !this.currentDiagramContentModelConnectionIds.includes(o.connection.id))
      .map(o => {
        const origin = this.modelModule.objects[o.connection.originId]
        const target = this.modelModule.objects[o.connection.targetId]
        const directConnectionOriginProtected = this.isModelObjectProtected(origin.id)
        const higherDisabled = o.type === 'higher' && (originProtected || directConnectionOriginProtected)

        return {
          diagramCount: Object.keys(o.connection.diagrams).length,
          direction: o.connection.direction,
          disabled: higherDisabled,
          id: o.connection.id,
          originObjectName: origin?.name || `${origin.type.slice(0, 1).toUpperCase()}${origin.type.slice(1)}`,
          targetObjectName: target?.name || `${target.type.slice(0, 1).toUpperCase()}${target.type.slice(1)}`,
          text: o.connection.name || 'Connection',
          tooltip: higherDisabled ? 'Connection is only editable by owners and admins' : undefined,
          type: o.type
        }
      })
      .sort((a, b) => {
        if (a.diagramCount === b.diagramCount) {
          return a.text.localeCompare(b.text)
        } else {
          return b.diagramCount - a.diagramCount
        }
      })
  }

  get modelConnectionItemsFuzzy () {
    if (this.editorModule.connectionCreateFilterName) {
      const search = new Fuse(this.modelConnectionItems, {
        keys: [
          'text',
          'type'
        ],
        threshold: 0.3
      })
      return search.search(this.editorModule.connectionCreateFilterName).map(o => o.item)
    } else {
      return this.modelConnectionItems
    }
  }

  isModelObjectProtected (id: string) {
    if (this.currentLandscapePermission === 'admin') { return false }
    if (!id) { return false }
    const modelObject = this.modelModule.objects[id]
    return (
      modelObject &&
      modelObject.teamOnlyEditing &&
      !!modelObject.teamIds.length &&
      !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
    )
  }

  mounted () {
    this.menuRef.open()
  }

  updateDimensions () {
    this.menuRef.updateDimensions()
  }

  deleteFromDiagram () {
    const currentDiagram = this.currentDiagram
    if (!currentDiagram || !this.currentDiagramContent || !this.currentConnection) {
      return
    }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    if (currentDiagram.status === 'draft') {
      revertTasks.push({
        id: currentDiagram.id,
        props: {
          connections: {
            $add: {
              [this.currentConnection.id]: this.currentConnection
            }
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagram.id, {
        connections: {
          $remove: [this.currentConnection.id]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      revertTasks.push({
        id: currentDiagram.id,
        props: {
          connections: {
            $add: {
              [this.currentConnection.id]: this.currentConnection
            }
          }
        },
        type: 'diagram-content-update'
      }, {
        route: this.$route,
        type: 'navigation'
      })

      const { diagramContent, diagramContentUpdate, modelConnectionDiagramAdd, modelConnectionDiagramRemove } = this.diagramModule.generateDiagramContentCommit(currentDiagram.id, {
        connections: {
          $remove: [this.currentConnection.id]
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)
      this.modelModule.setConnectionDiagrams({ modelConnectionDiagramAdd, modelConnectionDiagramRemove })
      this.editorModule.addToTaskQueue({
        func: () => this.diagramModule.diagramContentUpdate({
          diagramId: currentDiagram.id,
          landscapeId: this.currentLandscape.id,
          props: diagramContentUpdate,
          versionId: this.currentVersion.id
        })
      })

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    }

    tasks.push({
      route: this.$route,
      type: 'navigation'
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks
    })

    this.$emit('delete-connection')
  }
}
