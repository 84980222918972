
import { DiagramConnection, ModelConnection } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Location } from 'vue-router'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import { ModelModule } from '../../store'

@Component({
  name: 'ModelConnectionType'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly connection!: DiagramConnection
  @Prop() readonly model!: ModelConnection

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get lowerConnection () {
    const diagramConnection = Object.values(this.model.diagrams).find(o => o.connectionId === this.connection?.id)
    return diagramConnection && (this.model.originId !== diagramConnection.originModelId && this.model.targetId !== diagramConnection.targetModelId)
  }

  get to (): Location | undefined {
    const directConnection = Object.values(this.model.diagrams).find(o => o.originModelId === this.model.originId && o.targetModelId === this.model.targetId)
    const directConnectionDiagram = directConnection ? this.diagramModule.diagrams[directConnection.id] : undefined
    const directConnectionDiagramModel = directConnectionDiagram ? this.modelModule.objects[directConnectionDiagram.modelId] : undefined

    if (directConnection && directConnectionDiagram && directConnectionDiagramModel) {
      return {
        name: this.currentShareLink ? 'share-diagram' : this.currentVersionId === 'latest' ? 'editor-diagram' : 'version-diagram',
        query: this.$setQuery({
          connection: directConnection.connectionId,
          diagram: directConnectionDiagram.handleId,
          flow: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined,
          model: directConnectionDiagramModel.handleId,
          object: undefined,
          x1: undefined,
          x2: undefined,
          y1: undefined,
          y2: undefined
        })
      }
    }
  }
}
